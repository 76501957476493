var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "tui tuim ui card item donor", attrs: { to: _vm.link } },
    [
      _c("div", { staticClass: "content spacer" }, [
        _c("div", { staticClass: "left meta" }, [_vm._v("Desde 10/02/15")]),
        _c("h3", { staticClass: "name" }, [
          _vm._v(
            "Anna Lethicia Tavares Martinelli Manfredini Lorem ipsum, dolor sit amet"
          )
        ]),
        _c("div", { staticClass: "donation-count" }, [
          _c(
            "div",
            { staticClass: "icons" },
            [
              _c("t-status-icon", {
                attrs: { icon: "check", type: "success", size: "25px" }
              }),
              _c("t-status-icon", {
                attrs: { icon: "times", type: "error", size: "25px" }
              }),
              _vm._l([].concat(Array(6)), function(item, i) {
                return _c("t-status-icon", { key: i, attrs: { size: "25px" } })
              })
            ],
            2
          ),
          _c("p", [_vm._v("2/8 doações ativas")])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }