<template>
  <router-link :to="link" class="tui tuim ui card item donor">
    <div class="content spacer">
      <div class="left meta">Desde 10/02/15</div>
      <h3 class="name">Anna Lethicia Tavares Martinelli Manfredini Lorem ipsum, dolor sit amet</h3>
      <div class="donation-count">
        <div class="icons">
          <t-status-icon icon="check" type="success" size="25px" />
          <t-status-icon icon="times" type="error" size="25px" />
          <t-status-icon v-for="(item, i) in [...Array(6)]" :key="i" size="25px" />
        </div>
        <p>2/8 doações ativas</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import TStatusIcon from '@/components/status/icon.vue'

export default {
  name: 'TDonorItem',
  data() {
    return {
      link: {
        name: 'donors.single',
        params: { id: faker.random.number(99999) }
      }
    }
  },
  components: {
    TStatusIcon
  }
}
</script>
