var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: "tui tuim status icon " + _vm.type,
      style: { width: _vm.size, height: _vm.size }
    },
    [
      _c("span", {
        staticClass: "icon-status icoico",
        domProps: { innerHTML: _vm._s(_vm.getFormatedIcon) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }