<template>
  <span :class="`tui tuim status icon ${type}`" :style="{ width: size, height: size }">
    <span v-html="getFormatedIcon" class="icon-status icoico"/>
    <!-- <t-icon v-if="icon" :icon="[ 'fas', formIcon ]" class="icon-status" /> -->
  </span>
</template>

<script>
import { getStatusIcon } from '@/helpers'

export default {
  name: 'TStatusIcon',
  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'times'
    },
    size: {
      type: String,
      default: '30px'
    }
  },

  computed: {

    getFormatedIcon () {
      return getStatusIcon(this.icon)
    },

    formIcon () {
      switch (this.icon) {
        case 'error':
        case 'hard_bounce':
        case 'canceled':
          return 'times' // red
        case 'paid':
        case 'over_paid':
        case 'under_paid':
          return 'check' // green
        case 'soft_bounce':
          return 'minus-circle' // yellow
        case 'charged_back':
        case 'refunded':
          return 'exclamation-triangle' // purple
        case 'pending':
        case 'generated':
        default:
          return `sync` // gray
      }
    }
  }

}
</script>

<style lang="scss">

$error:       #fd9093;
$success:     #b4db75;
$pending:     #d1d1d1;
$processing:  #f9d96a;
$chargeback:  #B489C4;
$gray-lighter:#7a7a7a;
$white:       #fff;

.tui.tuim.status.icon {
  align-items: center;
  border-radius: 50%;
  color: #d1d1d1;
  // background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  padding: 5px;
  text-align: center;

  // &.error, &.hard_bounce, &.canceled { background: $error; color: $white; }
  // &.success, &.paid, &.over_paid, &.under_paid { background: $success; color: $white; }
  // &.soft_bounce { background: $processing; color: $white; }
  // &.pending, &.generated { background: $pending; color: $white; }
  // &.charged_back, &.refunded { background: $chargeback; color: $white; }

  .icon-status {
    color: inherit;

    &.icoico .icon { font-size: 25px }
  }
}
</style>
